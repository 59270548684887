import { mdiAlert, mdiAlertCircle, mdiAlertRhombus, mdiCheckCircle, mdiCircleHalfFull, mdiMinusCircle } from '@mdi/js';
import { timezone } from '../config/env';
import { ExecutionStatus } from '../resources/history-list/history-list-types';

export function getStatusColor(status?: ExecutionStatus): string {
  switch (status) {
    case ExecutionStatus.OK:
      return 'var(--warning-success)';
    case ExecutionStatus.ERROR:
      return 'var(--warning-orange)';
    case ExecutionStatus.FATAL:
      return 'var(--warning-error)';
    case ExecutionStatus.SKIPPED_SUPPRESSED:
      return 'var(--detail-gray)';
    case ExecutionStatus.SOFT_LAUNCH:
      return 'var(--warning-blue)';
    case ExecutionStatus.WARN:
      return '#f0bb00';
    case ExecutionStatus.PROCESSING:
      return '#4994ee';
    default:
      return 'black';
  }
}

export function getStatusBgColor(status?: ExecutionStatus): string | undefined {
  switch (status) {
    case ExecutionStatus.OK:
      return 'var(--tertiary)';
    case ExecutionStatus.ERROR:
      return 'var(--bg-yellow)';
    case ExecutionStatus.FATAL:
      return 'var(--bg-red)';
    case ExecutionStatus.SKIPPED_SUPPRESSED:
      return 'var(--detail-border)';
    case ExecutionStatus.SOFT_LAUNCH:
      return '#4994EE26';
    case ExecutionStatus.WARN:
      return '#FFFBD6';
    case ExecutionStatus.PROCESSING:
      return '#4994EE26';
  }
}

export function getStatusIconPath(status?: ExecutionStatus): string | undefined {
  switch (status) {
    case ExecutionStatus.OK:
      return mdiCheckCircle;
    case ExecutionStatus.ERROR:
      return mdiAlertCircle;
    case ExecutionStatus.FATAL:
      return mdiMinusCircle;
    case ExecutionStatus.SKIPPED_SUPPRESSED:
      return mdiAlertRhombus;
    case ExecutionStatus.SOFT_LAUNCH:
      return mdiAlertCircle;
    case ExecutionStatus.WARN:
      return mdiAlert;
    case ExecutionStatus.PROCESSING:
      return mdiCircleHalfFull;
  }
}

export function shortenUUID(uuid?: string): string {
  return uuid ? uuid.substring(0, 4) + '...' + uuid.substring(uuid.length - 4) : '';
}

export function dateToCorrectTimezone(value: string): string {
  if (timezone === '') {
    return new Date(value).toLocaleString();
  }

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return new Date(value + 'Z').toLocaleString('en-US', options);
}
