import { Environments, Markets } from '../@types/configuration';

export interface EnvironmentVariables {
  ADMIN_API_URL: string;
  API_URL: string;
  MARKET: Markets;
  ENV: Environments;
  OKTA_ISSUER: string;
  OKTA_CLIENT_ID: string;
  OKTA_SCOPES: string[];
  TIMEZONE: string;
  TIMEZONE_NAME: string;
  EMAIL_LABEL: string;
}

const makeAppTitle = (): string => {
  const titleMap: Record<Markets, string> = {
    [Markets.NA]: 'North America',
    [Markets.INDIA]: 'India',
    [Markets.THAILAND]: 'Thailand',
    [Markets.KOREA]: 'Korea',
  };

  return titleMap[environment.MARKET] ?? 'ACDP-RT';
};

const environment: EnvironmentVariables = Object.seal({
  ADMIN_API_URL: process.env.REACT_APP_ADMIN_API_URL!,
  API_URL: process.env.REACT_APP_API_URL!,
  MARKET: process.env.REACT_APP_MARKET as Markets,
  ENV: process.env.REACT_APP_ENV as Environments,
  OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER!,
  OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID!,
  OKTA_SCOPES: process.env.REACT_APP_OKTA_SCOPES!.split(','),
  TIMEZONE: process.env.REACT_APP_TIMEZONE ?? '',
  TIMEZONE_NAME: process.env.REACT_APP_TIMEZONE_NAME ?? '',
  EMAIL_LABEL: process.env.REACT_APP_EMAIL_LABEL ?? 'Email',
});

export const acdpGroupsPrefix = 'groups-ACDP-RT';

export const isNA = environment.MARKET === Markets.NA;

export const isPROD = environment.ENV === Environments.PROD;

export const appTitle = makeAppTitle();

export const timezone = environment.TIMEZONE;

export const timezoneName = environment.TIMEZONE_NAME;

export default environment;
